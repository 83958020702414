import React, { useState } from 'react'
import { useRef } from 'react';
import { Icon } from '@iconify-icon/react';
import './Register.css'
import { content, cssControl, outlet, regex, states, validation } from '../../helper/Helper';
import Button from '../common/Button';
import { Link } from 'react-router-dom';


export default function RegisterForm({ signIn, open }) {

	const [state, setState] = useState(false);
	const [visible, setVisible] = useState(false);
	const [check, setCheck] = useState(false)
	const [selectedOption, setSelectedOption] = useState("");


	const toggleBtn = () => {
		setState(prevState => !prevState);
	}

	const toggleEye = () => {
		setVisible(prevVisible => !prevVisible);
	}

	// const [input, setInput] = useState("");
	// const inputHandler = (e) => {
	// 	setInput(e.target.value);
	// };

	const name = useRef();
	const lastname = useRef();
	const email = useRef();
	const password = useRef();
	const cpassword = useRef();
	const organisation = useRef();
	const position = useRef();
	const mobileNo = useRef();
	const sevenNtkContent = useRef();
	const Address = useRef();
	const suburb = useRef();
	const postcode = useRef();
	const checkBox = useRef();
	const outletRef = useRef('');
	const statesRef = useRef('');
	const others = useRef('');


	const nameError = useRef();
	const lastnameError = useRef();
	const emailError = useRef();
	const passwordError = useRef();
	const cpasswordError = useRef();
	const organisationError = useRef();
	const positionError = useRef();
	const mobileNoError = useRef();
	const sevenNtkContentError = useRef();
	const AddressError = useRef();
	const suburbError = useRef();
	const postcodeError = useRef();
	const stateError = useRef();
	const outletRefError = useRef();
	const otherError = useRef()
	let isError = "";


	function firstNameHandler() {
		isError = false;
		name.current.className = cssControl?.css
		nameError.current.innerHTML = ""
		if (name.current.value !== "") {
			if (!regex?.nameRegex.test(name.current.value)) {
				name.current.className = cssControl?.validCss
				nameError.current.innerHTML = validation?.nameInvalid
				isError = true;
			}
		} else {
			name.current.className = cssControl?.validCss
			nameError.current.innerHTML = validation?.firstName
			isError = true;
		}
		// console.log(isError, "firstname")

	}

	function lastNameHandler() {
		isError = false;
		// console.log(isError, "lastname")
		lastname.current.className = cssControl?.css
		lastnameError.current.innerHTML = ""
		if (lastname.current.value !== "") {
			if (!regex?.nameRegex.test(lastname.current.value)) {
				lastname.current.className = cssControl?.validCss
				lastnameError.current.innerHTML = validation?.nameInvalid
				isError = true;
			}
		} else {
			lastname.current.className = cssControl?.validCss
			lastnameError.current.innerHTML = validation?.lastName
			isError = true;
		}

	}

	function emailHandler() {
		isError = false;
		email.current.className = cssControl?.css
		emailError.current.innerHTML = ""
		if (email.current.value !== "") {
			if (!regex?.emailRegex.test(email.current.value)) {
				email.current.className = cssControl?.validCss
				emailError.current.innerHTML = validation?.emailInvalid
				isError = true;
			}
		} else {
			email.current.className = cssControl?.validCss
			emailError.current.innerHTML = validation?.email
			isError = true;
		}
		// console.log(isError, "email")

	}

	function passwordHandler() {
		isError = false;
		password.current.className = cssControl?.css
		passwordError.current.innerHTML = ""
		cpassword.current.className = cssControl?.css
		cpasswordError.current.innerHTML = ""
		if (password.current.value !== "") {
			if (!regex?.passRegex.test(password.current.value)) {
				password.current.className = cssControl?.validCss
				passwordError.current.innerHTML = validation?.passwordInvalid
				isError = true;
			}
			if (cpassword.current.value !== "") {
				if (password.current.value !== cpassword.current.value) {
					cpassword.current.className = cssControl?.validCss
					cpasswordError.current.innerHTML = validation?.p_NotSame
					isError = true
				}
			} else {
				cpassword.current.className = cssControl?.validCss
				cpasswordError.current.innerHTML = validation?.p_ReEnter
				isError = true;
			}
		} else {
			password.current.className = cssControl?.validCss
			passwordError.current.innerHTML = validation?.password
			isError = true;
		}

		// console.log(isError, "password")

	}

	function orgHandler() {
		isError = false;
		organisation.current.className = cssControl?.css
		organisationError.current.innerHTML = ""
		if (organisation.current.value.trim() === "") {
			organisation.current.className = cssControl?.validCss
			organisationError.current.innerHTML = validation?.organisation
			isError = true;
		}
		// console.log(isError, "org")

	}

	function positionHandler() {
		isError = false;
		position.current.className = cssControl?.css
		positionError.current.innerHTML = ""
		if (position.current.value.trim() === "") {
			position.current.className = cssControl?.validCss
			positionError.current.innerHTML = validation?.position
			isError = true;
		}
		// console.log(isError, "possiton")

	}

	function mobileHandler() {
		isError = false;
		mobileNo.current.className = cssControl?.css
		mobileNoError.current.innerHTML = ""
		if (mobileNo.current.value !== "") {
			if (!regex?.mobilenoRegex.test(mobileNo.current.value)) {
				mobileNo.current.className = cssControl?.validCss
				mobileNoError.current.innerHTML = validation?.mobileNoInvalid
				isError = true;
			}
		} else {
			mobileNo.current.className = cssControl?.validCss
			mobileNoError.current.innerHTML = validation?.mobileNo
			isError = true;
		}
		// console.log(isError, "mobile")

	}

	function sevenNtkHandler() {
		isError = false;
		sevenNtkContent.current.className = cssControl?.css
		sevenNtkContentError.current.innerHTML = ""
		if (sevenNtkContent.current.value.trim() === "") {
			sevenNtkContent.current.className = cssControl?.validCss
			sevenNtkContentError.current.innerHTML = validation?.sevenNtkContent
			isError = true;
		}
		// console.log(isError, "seven")

	}

	function addressHandler() {
		isError = false;
		Address.current.className = cssControl?.addCss
		AddressError.current.innerHTML = ""

		if (Address.current.value.trim() === "") {
			Address.current.className = cssControl?.validAddCss
			AddressError.current.innerHTML = validation?.Address
			isError = true;
		}
		// console.log(isError, "address")

	}

	function subHandler() {
		isError = false;
		suburb.current.className = cssControl?.css
		suburbError.current.innerHTML = ""
		if (suburb.current.value.trim() === "") {
			suburb.current.className = cssControl?.validCss
			suburbError.current.innerHTML = validation?.suburb
			isError = true;
		}
		// console.log(isError, "sub")

	}

	function postCodeHandler() {
		isError = false;
		postcode.current.className = cssControl?.css
		postcodeError.current.innerHTML = ""

		if (postcode.current.value !== "") {
			if (!regex?.postCodeRegex.test(postcode.current.value)) {
				postcode.current.className = cssControl?.validCss
				postcodeError.current.innerHTML = validation?.postcodeInvalid
				isError = true;
			}
		} else {
			postcode.current.className = cssControl?.validCss
			postcodeError.current.innerHTML = validation?.postcode
			isError = true;
		}
		// console.log(isError, "post")

	}

	function checkHandler() {
		let isError = false;
		if (!checkBox.current.checked) {
			setCheck(true)
			setTimeout(() => {
				setCheck(false)
			}, 2000);
			checkBox.current.className = validation?.checkboxInvalid
			isError = true;
		} else {
			setCheck(false)
			checkBox.current.className = validation?.checkbox
		}
		// console.log(isError, "checkbox")
	}

	function stateHandler() {
		isError = false;
		statesRef.current.className = cssControl?.css
		stateError.current.innerHTML = ""
		if (statesRef.current.value.trim() === "") {
			statesRef.current.className = cssControl?.validCss
			stateError.current.innerHTML = validation?.stateInvalid
			isError = true;
		}
		// console.log(isError, "state")
	}

	function submitHandler(event) {
		event.preventDefault();
		firstNameHandler();
		lastNameHandler();
		emailHandler();
		passwordHandler();
		orgHandler();
		positionHandler();
		mobileHandler();
		sevenNtkHandler();
		addressHandler();
		subHandler();
		postCodeHandler();
		checkHandler();
		stateHandler();

		outletRef.current.className = cssControl?.css
		outletRefError.current.innerHTML = ""

		if (outletRef.current.value === "") {
			outletRef.current.className = cssControl?.validCss
			outletRefError.current.innerHTML = validation?.outlet
			isError = true;
		}
		// console.log(isError, "outlet")

		if (outletRef.current.value === "Other") {
			others.current.className = cssControl?.css
			otherError.current.innerHTML = ""
			if (others.current.value.trim() === "") {
				others.current.className = cssControl?.validCss
				otherError.current.innerHTML = validation?.other
				isError = true;
			}
		}
		// console.log(isError, "other")s

		if (isError == false) {
			signIn(name.current.value, lastname.current.value, email.current.value, password.current.value, organisation.current.value, position.current.value, mobileNo.current.value, sevenNtkContent.current.value, outletRef.current.value === "Other" ? others.current.value : outletRef.current.value, Address.current.value, suburb.current.value, statesRef.current.value, postcode.current.value)
			// console.log("true")
		}
	}

	return (
		<form className="w-full  my-6" onSubmit={submitHandler}>
			<div className="grid grid-cols-2 gap-4 xl:max-w-[85%]">
				<div className="col-span-2 lg:col-span-1">
					<label htmlFor="name" className="label-text ">First Name</label>
					<input type="name" className="input-css" ref={name} onChange={firstNameHandler} />
					<div className="error" ref={nameError}></div>
				</div>

				<div className="col-span-2 lg:col-span-1">
					<label htmlFor="l-name" className="label-text ">Last Name</label>
					<input type="l-name" className="input-css" ref={lastname} onChange={lastNameHandler} />
					<div className="error" ref={lastnameError}></div>
				</div>

				<div className="col-span-2">
					<label htmlFor="email" className="label-text ">Email Address</label>
					<input type="text" className='input-css' ref={email} onChange={emailHandler} />
					<div className="error" ref={emailError}></div>
				</div>

				<div className="col-span-2 relative">
					<div className='flex justify-between items-center'>
						<label htmlFor="pass-word" className="label-text " >Password</label>
						<div className="flex group relative items-center text-gray-3 text-[12px] md:text-[10px] xl:text-[12px] bg-no-repeat pr-2 "
						>
							<div className={` absolute w-[230px] top-[-125px] left-[-99px] mid-xl:top-[-127px] mid-xl:left-[-100px] scale-0 rounded-lg bg-gray-200 shadow-sm text-xs text-black group-hover:scale-100`}>
								<div className="w-full h-full flex flex-col items-start relative border-2 p-2 rounded-lg">
									<div className='flex items-center'>
										<Icon icon="codicon:circle-small-filled" className='icon-color mr-1' width={17} height={17} />
										At least 8 characters
									</div>
									<div className='flex items-center'>
										<Icon icon="codicon:circle-small-filled" className='icon-color mr-1' width={17} height={17} />
										At least one number
									</div>
									<div className='flex items-center'>
										<Icon icon="codicon:circle-small-filled" className='icon-color mr-1' width={17} height={17} />
										At least one special character
									</div>
									<div className='flex items-center'>
										<Icon icon="codicon:circle-small-filled" className='icon-color mr-1' width={17} height={17} />
										At least one lowercase character
									</div>
									<div className='flex items-center'>
										<Icon icon="codicon:circle-small-filled" className='icon-color mr-1' width={17} height={17} />
										At least one uppercase character
									</div>
									<div className={`w-3 h-3 bg-gray-200 rounded-[2px] rotate-45 absolute top-[96%] left-[44%]`}></div>
								</div>
							</div>

							<Icon icon="system-uicons:info-circle" className='alert-color mr-1' width={17} height={17} />Password must be strong password
						</div>
					</div>
					<input type={state ? "text" : "password"} className='input-css' ref={password} onChange={passwordHandler} />
					<span className={`absolute top-[39px] sm:top-[42px] right-4 md:right-7 lg:right-4 cursor-pointer`} onClick={toggleBtn}>
						{
							state
								?
								<Icon icon="codicon:eye-closed" className='icon-color' width={20} height={20} />
								:
								<Icon icon="codicon:eye" className='icon-color' width={20} height={20} />
						}
					</span>
					<div className="error" ref={passwordError}></div>
				</div>

				<div className="col-span-2 relative">
					<label htmlFor="c-pass-word" className="label-text ">Confirm Password</label>
					<input type={visible ? "text" : "password"}
						className="input-css" ref={cpassword} onChange={passwordHandler} />
					<span className='absolute top-[42px] right-4 md:right-7 lg:right-4 cursor-pointer' onClick={toggleEye}>
						{
							visible
								?
								<Icon icon="codicon:eye-closed" className='icon-color' width={20} height={20} />
								:
								<Icon icon="codicon:eye" className='icon-color' width={20} height={20} />
						}
					</span>
					<div className="error" ref={cpasswordError}></div>
				</div>

				<div className="col-span-2 lg:col-span-1">
					<label htmlFor="organisation" className="label-text ">Organisation</label>
					<input type="text" className="input-css" ref={organisation} onChange={orgHandler} />
					<div className="error" ref={organisationError}></div>
				</div>

				<div className="col-span-2 lg:col-span-1">
					<label htmlFor="position" className="label-text ">Position</label>
					<input type="text" className="input-css" ref={position} onChange={positionHandler} />
					<div className="error" ref={positionError}></div>
				</div>

				<div className="col-span-2 lg:col-span-1">
					<div className='flex justify-between items-center'>
						<label htmlFor="m-number" className="label-text ">Mobile Phone</label>
						<div className="flex items-center text-gray-3 text-[12px] md:text-[10px] xl:text-[12px] bg-no-repeat pr-2 "
						> <Icon icon="system-uicons:info-circle" className='alert-color mr-1' width={17} height={17} /> Don't prefix 0</div>
					</div>
					<input type="text" className="input-css" ref={mobileNo} maxLength={9} onChange={mobileHandler} />
					<div className="error" ref={mobileNoError}></div>
				</div>


				<div className="col-span-2 lg:col-span-1">
					<label htmlFor="n-number" className="label-text ">Seven Network Contact</label>
					<input type="text" className="input-css" ref={sevenNtkContent} onChange={sevenNtkHandler} />
					<div className="error" ref={sevenNtkContentError}></div>
				</div>

				<div className="col-span-2 Arrow relative">
					<label htmlFor="o-type" className="label-text ">Outlet Type</label>
					<Icon icon="codicon:chevron-down" width={23} height={23} className='absolute top-[38px] right-4 icon-color' />
					<select
						value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}
						className="input-css h-[47px]" ref={outletRef}>
						<option value="">Please select outlet</option>
						{outlet && outlet.map((data, index) => {
							return (
								<option key={index} value={data.type}>{data.type}</option>
							)
						})}
					</select>
					<div className="error" ref={outletRefError}></div>
					{selectedOption === "Other" && (
						<div>
							<input className='border input-css mt-1' placeholder='Enter Outlet-type' type="text" id="otherOption" ref={others} name="otherOption" />
							<div className="error" ref={otherError}></div>

						</div>
					)}
				</div>

				<div className="col-span-2 ">
					<div className='flex justify-between items-center'>
						<label htmlFor="c-pass-word" className="label-text ">Address</label>
						{/* <div className="text-gray-3 text-[12px] md:text-[10px] xl:text-[12px] pr-2">{input.length}/255</div> */}
					</div>
					<textarea maxLength={255} rows={4} className="bg-light-gray-3 p-2 rounded-lg border border-light-gray-4 border-1  w-full text-black-color-3 resize-none  outline-none" ref={Address} onChange={addressHandler} ></textarea>
					<div className="error" ref={AddressError}></div>
				</div>

				<div className="col-span-2 lg:col-span-1">
					<label htmlFor="suburb" className="label-text ">Suburb</label>
					<input type="text" className="input-css" ref={suburb} onChange={subHandler} />
					<div className="error" ref={suburbError}></div>
				</div>


				<div className="col-span-2 lg:col-span-1 Arrow relative">
					<label htmlFor="state" className="label-text ">State</label>
					<Icon icon="codicon:chevron-down" width={23} height={23} className='absolute top-[38px] right-4 icon-color' />
					<select onChange={stateHandler}
						className="input-css h-[47px]" ref={statesRef}>
						<option value="">Please select State</option>
						{states && states.map((value, index) => {
							return (
								<option key={index}>{value.name}</option>
							)
						})}
					</select>
					<div className="error" ref={stateError}></div>
				</div>

				<div className="col-span-2">
					<label htmlFor="p-code" className="label-text ">Postcode</label>
					<input type="text" className="input-css" maxLength={4} ref={postcode} onChange={postCodeHandler} />
					<div className="error" ref={postcodeError}></div>
				</div>

				<div className="flex mb-4 col-span-2 relative items-start">
					{check === true ?
						<div className={` absolute text-center w-20 sm:w-32 top-[38px] left-[-10px] sm:left-[-50px] mid-xl:top-[40px] mid-xl:left-[-52px]  rounded-lg bg-gray-200 shadow-lg text-xs text-black`}>
							<div className="w-full h-full relative p-2 rounded-lg">
								<span>{content.tooltip}</span>
								<div className={`w-3 h-3 bg-gray-200 rounded-[2px] rotate-45 absolute bottom-[86%] sm:bottom-[81%] sm:left-[40%]`}></div>
							</div>
						</div> : ""}
					<input id="default-checkbox" ref={checkBox} type="checkbox" onChange={checkHandler} value=""
						className="sm:w-5 sm:h-5 sm:mt-1 w-8 h-8 accent-red-color-2 bg-gray-100 border-gray-300 rounded" />
					<label htmlFor="default-checkbox" className="ml-2 text-sm text-light-gray-2 lg:text-base">
						I have agreed on Terms & Conditions. Please read <span onClick={() => open(true)} className="text-red-color-2 cursor-pointer underline underline-offset-1 ">privacy policy</span> for more detailed information. User will be responsible for any content related
						issue.
					</label>
				</div>
				<div className="col-span-2">
					<Button text={"Apply Now"} />
				</div>
				<div className="col-span-2 my-1 text-center">
					<p className="font-medium md:text-lg text-base">Already have an account?
						<Link to="/" className="text-red-color-2"> Login Here</Link></p>
				</div>
			</div>
		</form >
	)
}

