import React, { useState } from 'react'
import { useRef } from 'react';
import './Login.css'
import { cssControl, regex, validation } from '../../helper/Helper';
import Button from '../common/Button';
import { Icon } from '@iconify-icon/react';
import { useNavigate, Link } from 'react-router-dom';



export default function LoginForm({ login, forgot }) {
	const Naviagte = useNavigate()
	const [visible, setVisible] = useState(false);
	const [passwordText, setPasswordText] = useState(false)
	const [toggleForm, setToggleForm] = useState(true) // if true render login form , if false render forget password form


	const email = useRef();
	const password = useRef();
	const emailError = useRef()
	const passwordError = useRef();

	const toggleEye = () => {
		setVisible(prevVisible => !prevVisible);
	}

	function TextHandler(event) {
		if (event.target.value.length === 0) {
			setPasswordText(false)

		} else {
			setPasswordText(true)
		}
	}

	function SubmitHandler(event) {
		event.preventDefault();
		if (email.current) email.current.className = cssControl?.css2
		if (password.current) password.current.className = cssControl?.css2
		if (emailError.current) emailError.current.innerHTML = ""
		if (passwordError.current) passwordError.current.innerHTML = ""
		let isError = false;
		console.log(email?.current)

		if (email.current.value !== "") {
			if (!regex.emailRegex.test(email.current.value)) {
				emailError.current.innerHTML = validation?.emailInvalid
				isError = true;
			}
		} else {
			email.current.className = cssControl?.validCss2
			emailError.current.innerHTML = validation?.email
			isError = true;
		}
		if (password?.current) {
			if (password.current.value.trim() === "") {
				password.current.className = cssControl?.validCss2
				passwordError.current.innerHTML = validation?.password
				isError = true;
			}
		}



		if (isError === false) {
			if (toggleForm === true) {
				login(email.current.value, password.current.value)
			}
			else {
				forgot(email.current.value)
			}
		}

	}


	return (
		<>
			{
				(toggleForm === true)
					?
					<div id='login-form' className='lg:justify-center lg:flex lg:flex-col form-Block w-full lg:h-full '>
						<div className=' flex justify-center space-x-2 sm:space-x-3 pb-6'>
							<span className='welcome-bold'>Welcome</span>
							<span className='to-normal '> to </span>
							<span className='text-screen'>7Screening</span>
						</div>
						<form className=" space-y-4 mid-xl:space-y-6" onSubmit={SubmitHandler}>
							<div className='space-y-1'>
								<input type="text" ref={email} className="inputCss" placeholder="Email" />
								<div className='error-css' ref={emailError}></div>
							</div>
							<div className="space-y-1 relative" >
								<input id="password" ref={password} onKeyUp={(event) => TextHandler(event)} type={visible ? "text" : "password"} className="inputCss" placeholder="Password" />
								<span className='absolute top-2 mid-xl:top-4 right-4 sm:right-6 lg:right-4 cursor-pointer' onClick={toggleEye}>
									{
										visible
											?
											<Icon icon="codicon:eye-closed" className='icon-color' width={20} height={20} />
											:
											<Icon icon="codicon:eye" className='icon-color' width={20} height={20} />
									}
								</span>
								<div className='error-css' ref={passwordError}></div>
							</div>

							<Button text="Login" />

							{passwordText === true
								?
								<div className=' text-light-gray justify-center text-[13px] mid-xl:text-[18px] cairoRegular'>
									<span className='cairoBold'>Please notice that your password will expire in 3 months.</span>
									Hence you have to keep it updated every-time you receive mail. Do read your
									<Link to="/password-policy" className="text-red-color underline underline-offset-1"> Password Policy</Link>
								</div>
								:
								<div className='min-h-[39px] mid-xl:min-h-[53px] flex items-center text-light-gray justify-center cairoRegular text-[14px] mid-xl:text-[18px]'>
									<span className='cursor-pointer' onClick={() => setToggleForm(false)} >
										Forgot your password ?
									</span>
								</div>}
						</form>
						<div onClick={() => Naviagte('/register')} type="button" className='bg-light-gray cursor-pointer text-[12px] sm:text-[16px] mid-xl:text-[20px] text-center rounded-lg w-full text-white cairoSemiBold p-1.5 mid-xl:p-2.5'>
							Don't have an account ? Sign up now
						</div>
					</div >

					:
					<div id='login-form' className='lg:justify-center lg:flex lg:flex-col form-Block w-full lg:h-full '>
						<h2 className='flex justify-center text-xl xl:text-3xl text-white space-x-2 sm:space-x-3'>
							Forgot your Password
						</h2>
						<h2 className='flex justify-center m-4 text-xs sm:text-base lg:text-sm xl:text-base text-gray-400 pb-8 mid-xl:pb-16'>
							Reset password link will be sent to your email
						</h2>

						<form className="space-y-7 mid-xl:space-y-9" onSubmit={SubmitHandler}>
							<div className='space-y-1'>
								<input type="text" ref={email} className="inputCss" placeholder="Email" />
								<div className='error-css' ref={emailError}></div>
							</div>
							<Button text="Send" />
						</form>
					</div>



			}

			{/* <div id='login-form' className='lg:justify-center lg:flex lg:flex-col form-Block w-full lg:h-full '>
				{forgotpassword ? <div className=' flex justify-center space-x-2 sm:space-x-3 pb-6'>
					<span className='welcome-bold'>Welcome</span>
					<span className='to-normal '> to </span>
					<span className='text-screen'>7Screening</span>
				</div> :
					<>
						<h2 className='flex justify-center text-xl xl:text-3xl text-white space-x-2 sm:space-x-3'>
							Forgot your Password
						</h2>
						<h2 className='flex justify-center m-4 text-xs sm:text-base lg:text-sm xl:text-base text-gray-400 pb-8 mid-xl:pb-16'>
							Reset password link will be sent to your email
						</h2>
					</>
				}

				<form className={forgotpassword ? " space-y-4 mid-xl:space-y-6" : "space-y-7 mid-xl:space-y-9"} onSubmit={SubmitHandler}>
					<div className='space-y-1'>
						<input type="text" ref={email} className="inputCss" placeholder="Email" />
						<div className='error-css' ref={emailError}></div>
					</div>
					<div className={forgotpassword ? "space-y-1 relative" : " hidden"}>
						<input id="password" ref={password} onKeyUp={(event) => TextHandler(event)} type={visible ? "text" : "password"} className="inputCss" placeholder="Password" />
						<span className='absolute top-2 mid-xl:top-4 right-4 sm:right-6 lg:right-4 cursor-pointer' onClick={toggleEye}>
							{
								visible
									?
									<Icon icon="codicon:eye-closed" className='icon-color' width={20} height={20} />
									:
									<Icon icon="codicon:eye" className='icon-color' width={20} height={20} />
							}
						</span>
						<div className='error-css' ref={passwordError}></div>
					</div>

					<Button text={forgotpassword ? "Login" : " Send"} />
					{forgotpassword ? passwordText === true ?
						<div className=' text-light-gray justify-center text-[13px] mid-xl:text-[18px] cairoRegular'><span className='cairoBold'>Please notice that your password will expire in 3 months.</span> Hence you have to keep it updated every-time you receive mail. Do read your <Link to="/password-policy" className="text-red-color underline underline-offset-1"> Password Policy</Link></div>
						:
						<div className='min-h-[39px] mid-xl:min-h-[53px] flex items-center text-light-gray justify-center cairoRegular text-[14px] mid-xl:text-[18px]'>
							<button className='cursor-pointer' onClick={() => setForgotpassword(false)} > Forgot  your password ?</button></div> : ""}
				</form>
				{forgotpassword ? <div className='border-b border-light-gray my-4'></div> : ""}
				{forgotpassword ? <div onClick={() => Naviagte('/register')} type="button" className='bg-light-gray cursor-pointer text-[12px] sm:text-[16px] mid-xl:text-[20px] text-center rounded-lg w-full text-white cairoSemiBold p-1.5 mid-xl:p-2.5'>Don't have an account ? Sign up now
				</div> : ""}
			</div> */}
		</>
	)
}
