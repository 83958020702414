import React, { useRef, useState } from 'react'
import Button from '../common/Button';
import { Icon } from '@iconify-icon/react';
import './Reset.css'
import { cssControl, validation } from '../../helper/Helper';

export default function ResetPasswordForm({ reset }) {
	const [visible, setVisible] = useState(false);
	const [passwordText, setPasswordText] = useState(false)



	const toggleEye = () => {
		setVisible(prevVisible => !prevVisible);
	}

	function TextHandler(event) {
		if (event.target.value.length === 0) {
			setPasswordText(false)

		} else {
			setPasswordText(true)
		}
	}
	const password = useRef();
	const passwordError = useRef();


	function SubmitHandler(event) {
		event.preventDefault();
		password.current.className = cssControl?.css2
		passwordError.current.innerHTML = ""
		let isError = false;

		if (password.current.value.trim() === "") {
			password.current.className = cssControl?.validCss2
			passwordError.current.innerHTML = validation?.password
			isError = true;
		}

		if(isError == false) {
			reset(password.current.value)
		}
	}

	
	return (
		<div id='login-form' className='lg:justify-center lg:flex lg:flex-col form-Block w-full lg:h-full '>
			<h2 className='flex justify-center text-xl xl:text-3xl mb-8 pb-4 text-white space-x-2 sm:space-x-3 '>
				Reset your Password
			</h2>
			<form className='space-y-7 mid-xl:space-y-10 ' onSubmit={SubmitHandler}>
				<div className="col-span-2 relative space-y-4 mid-xl:space-y-7">
					<div className="space-y-1 relative">
						<input id="password" ref={password} onKeyUp={(event) => TextHandler(event)} type={visible ? "text" : "password"} className="inputCss" placeholder="New Password" />
						<span className='absolute top-2 mid-xl:top-2 right-4 sm:right-6 lg:right-4 cursor-pointer' onClick={toggleEye}>
							{
								visible
									?
									<Icon icon="codicon:eye-closed" className='icon-color' width={20} height={20} />
									:
									<Icon icon="codicon:eye" className='icon-color' width={20} height={20} />
							}
						</span>
						<div className='error-css' ref={passwordError} ></div>
					</div>
					<div className="space-y-1 relative">
						<input id="password" onKeyUp={(event) => TextHandler(event)} type={visible ? "text" : "password"} className="inputCss" placeholder="Confirm Password" />
						<span className='absolute top-2 mid-xl:top-2 right-4 sm:right-6 lg:right-4 cursor-pointer' onClick={toggleEye}>
							{
								visible
									?
									<Icon icon="codicon:eye-closed" className='icon-color' width={20} height={20} />
									:
									<Icon icon="codicon:eye" className='icon-color' width={20} height={20} />
							}
						</span>
						<div className='error-css' ></div>
					</div>
				</div>
				<Button text={"Submit"} />
			</form>
		</div>
	)
}
