import React, { useState } from 'react'
// import RedCorner from '../components/Login/images/redCorner.png'
import LoginForm from '../components/Login/LoginForm'
import Slider from '../components/common/Slider'
import '../components/Login/Login.css'
import axios from 'axios'
import { path } from '../routes/path'
import { ErrorModal, SuccessModal } from '../helper/Helper'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../reactRedux/Action'
import PrivacyPolicy from '../components/common/PrivacyPolicy'
import { FullScreenLoader } from '../components/common/FullScreenLoader'

export default function Login() {
	const data = useSelector((allData) => { return allData });
	const [newModal, setNewModal] = useState(false)
	const [isLoader, setIsLoader] = useState(false)


	// console.log(data)

	const dispatch = useDispatch();
	const Navigate = useNavigate()

	async function loginHandler(email, password) {
		setIsLoader(true)
		await axios.post(path.login, {
			email: email,
			password: password
		}).then((response) => {
			// console.log(response)
			dispatch(Actions.loginSuccess(response?.data?.data?.token))
			Navigate("/dashboard/admin")
			setIsLoader(false)
		}).catch((error) => {
			// console.log(error)
			setIsLoader(false)
			ErrorModal(error?.response?.data?.message || error?.message || "Please contact site Admin", "Something went wrong")


		})
	}

	async function forgotPasswordHandler(email) {
		setIsLoader(true)
		console.log(email)
		await axios.post(path.forgot, {
			email: email
		}).then((response) => {
			setIsLoader(false)
			SuccessModal(response.data.message)
		}).catch((error) => {
			setIsLoader(false)
			ErrorModal(error?.response?.data?.message || error?.message)
		})
	}

	return (
		<>
			{isLoader ? <FullScreenLoader /> : " "}
			{(newModal) ? <PrivacyPolicy closeModal={setNewModal} /> : ""}
			{/* <img className='top-red-corner z-50' src={RedCorner} alt="" /> */}
			<div className='min-h-screen bg-black-color flex items-center p-8 sm:p-10 lg:p-14 mid-xl:p-16 bg-[url("components/Login/images/redCorner.svg")] bg-[left_top] mid-xl:bg-[length:12rem] md:bg-[length:8rem] bg-[length:30%] bg-no-repeat '>
				<div className='basis-full flex lg:flex-row flex-col gap-4 lg:gap-2 '>
					<div className='slider-block'>
						<Slider />
					</div>

					<div className='basis-5/12 flex items-center'>
						<LoginForm forgot={forgotPasswordHandler} login={loginHandler} />
					</div>
				</div>
			</div>



			{/* footer */}
			<div className='w-full flex  justify-center fixed bottom-0 cairoRegular text-[12px] sm:text-[14px] mid-xl:text-[18px] bg-dark-gray py-2.5 mid-xl:py-4'>
				<span className="copy-rights font-normal text-gray-color">Copyright @2023
					<Link to="/seven-network" className="text-red-color"> Seven Network</Link>
					, Inc
					<span onClick={() => setNewModal(true)} className="text-red-color cursor-pointer"> | Privacy policy</span>
				</span>
			</div>
		</>

	)
}
