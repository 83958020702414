const baseUrl = "https://channel7ui.backend.fair.dental/api"


export const path = {
  register: `${baseUrl}/users/signup`,
  login: `${baseUrl}/users/login`,
  updateUser: `${baseUrl}/users/update`,
  forgot: `${baseUrl}/users/forgot-password`,
  resetpassword: `${baseUrl}/users/reset-password`,
  //   resetpassword:`${baseUrl}/users/reset-password/:token`,
}