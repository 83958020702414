import React from 'react'
import arrow from "../Admin-Dashboard/Header/images/red-box-arrow.svg"


export default function SideBar() {
    return (
        <div className="scheudle-red-box z-30">
            <h2 className="py-6 text-black-color-4 h-full flex flex-col justify-between items-center opacity-20 uppercase cairoExtraBold text-center">
                <p>S</p>
                <p>C</p>
                <p>H</p>
                <p>E</p>
                <p>D</p>
                <p>U</p>
                <p>L</p>
                <p>E</p>
            </h2>
            {/* <!-- Arrow Button for align Middle --> */}
            <button type="button"
                className="absolute -right-7 top-2/4 lg:-right-9 sm:-right-5 w-14 h-14 cursor-pointer z-30">
                <img src={arrow} alt="arrow" className="cursor-pointer" />
            </button>
            {/* <!-- Arrow Button for align Middle Close--> */}
        </div>
    )
}
