import React from 'react'
import redcross from '../Header/images/red-cross-icon.svg'
import Addusers from '../Header/images/add-user.svg'
import Addshow from "../Header/images/add-show.svg"
import Addevent from "../Header/images/add-event.svg"
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

export default function SubHeader({ open }) {

    const location = useLocation();
    return (
        <>
            <div className="sub-navbar w-full">
                <div className="flex lg:justify-between lg:items-center py-2 border-b border-gray-3 duration-500  translate-x-full lg:translate-x-0 lg:w-auto  lg:min-h-fit min-h-screen lg:bg-transparent bg-black-3 top-0 max-lg:items-center lg:flex-1 lg:flex" id="sub-navbar-sticky">
                    <ul className="flex flex-col lg:flex-row max-xl:hidden lg:space-x-5 xl:space-x-5 mid-xl:space-x-10 md:mt-0 lg:text-sm lg:font-medium">
                        <li>
                            <Link to="/dashboard/admin" className={`${location.pathname == "/dashboard/admin" ? "nav-link active" : "nav-link"} `}>Dashboard</Link>
                        </li>
                        <li>
                            <Link to="/dashboard/shows" className={`${location.pathname == "/dashboard/shows" ? "nav-link active" : "nav-link"} `}>Show</Link>
                        </li>
                        <li>
                            <Link to="/dashboard/events" className={`${location.pathname == "/dashboard/events" ? "nav-link active" : "nav-link"} `}>Events</Link>
                        </li>
                        <li>
                            <Link to="/dashboard/news" className={`${location.pathname == "/dashboard/news" ? "nav-link active" : "nav-link"} `}>News</Link>
                        </li>
                        <li>
                            <Link to="/dashboard/users" className={`${location.pathname == "/dashboard/users" ? "nav-link active" : "nav-link"} `}>Users</Link>
                        </li>
                        <li>
                            <Link to="/dashboard/groups" className={`${location.pathname == "/dashboard/groups" ? "nav-link active" : "nav-link"} `}>Groups</Link>
                        </li>
                    </ul>
                    <ul className="flex flex-col max-xl:w-full  max-xl:justify-between lg:flex-row lg:space-x-2 xl:space-x-4 mid-xl:space-x-6 3xl:space-x-10 md:mt-0 
                    2xl:text-lg lg:text-sm text-[16px] lg:font-medium">
                        <li>
                            <span onClick={() => open(true)} className="flex items-center text-gray-3 cursor-pointer hover:text-red font-normal py-2 transition duration-300 ease-in-out ">
                                <span><img src={Addusers} className="mid-xl:w-5 mid-xl:h-5 w-4 h-4 mr-2 " alt="" /></span>
                                Add User
                            </span>
                        </li>
                        <li>
                            <a href="/" className="flex items-center text-gray-3  font-normal py-2 hover:text-red transition duration-300 ease-in-out">
                                <span><img src={Addshow} className="mid-xl:w-5 mid-xl:h-5 w-4 h-4 mr-2" alt="" /></span>
                                Add Show
                            </a>
                        </li>
                        <li>
                            <a href="/" className="flex items-center text-gray-3  font-normal py-2 hover:text-red transition duration-300 ease-in-out">
                                <span><img src={Addevent} className="mid-xl:w-5 mid-xl:h-5 w-4 h-4 mr-2" alt="" /></span>
                                Add Episode
                            </a>
                        </li>
                        <li>
                            <a href="/" className="flex items-center text-gray-3  font-normal py-2 hover:text-red transition duration-300 ease-in-out">
                                <span><img src={Addevent} className="mid-xl:w-5 mid-xl:h-5 w-4 h-4 mr-2" alt="" /></span>
                                Add Event
                            </a>
                        </li>
                        <li>
                            <a href="/" className="flex items-center text-gray-3  font-normal py-2 hover:text-red transition duration-300 ease-in-out">
                                <span><img src={Addevent} className="mid-xl:w-5 mid-xl:h-5 w-4 h-4 mr-2" alt="" /></span>
                                Add News
                            </a>
                        </li>
                    </ul>
                    <button onClick="onToggleMenu(this)" name="close" className="absolute top-5 right-5 transition-all duration-300 group lg:hidden">
                        <img src={redcross} className="w-5 h-5" alt="" />
                    </button>
                </div>
            </div>
        </>
    )
}
