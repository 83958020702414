import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { useSelector } from "react-redux";
import Dashboard from "./pages/Dashboard"
import AdminDashboard from "../src/components/Admin-Dashboard/Dashboard/AdminDashboard"
import Users from "./components/Admin-Dashboard/Users/Users";
import ResetPassword from "./pages/ResetPassword";

function App() {
  const data = useSelector((allData) => { return allData });
  // const Navigate = useNavigate()
  // const { pathname } = useLocation()
  return (
    <>
      <Routes>
        {data.isLoggedIn ?
          <Route path="/dashboard" element={<Dashboard />} >
            <Route path="admin" element={<AdminDashboard />} />
            <Route path="users" element={<Users />} />
          </Route>
          : ""
        }
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset-password" element={<ResetPassword />} />

      </Routes>


    </>
  );
}

export default App;
