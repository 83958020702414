import Swal from 'sweetalert2'


export const validation = {

  firstName: "Please enter first name",
  nameInvalid: "Contain only characters",
  lastName: "Please enter last name",
  email: "Please enter email",
  emailInvalid: "Email is not valid",
  password: "Please enter password",
  p_NotSame: "Password doesn't match",
  passwordInvalid: "Minimum 8 characters, must contain one number, one special character",
  p_ReEnter: "Please enter password again",
  organisation: "Please enter organisation ",
  position: "Please enter position ",
  mobileNo: "Please enter mobile no.",
  mobileNoInvalid: "Must contain 9-digit",
  sevenNtkContent: "Please enter seven network contact",
  Address: "Please enter Address",
  suburb: "Please enter suburb",
  postcode: "Please enter postcode",
  postcodeInvalid: "Must contain 4-digit",
  outlet: "Please select outlet-type",
  other: "Please enter outlet-type",
  stateInvalid: "Please select state",
  checkbox: "sm:w-4 sm:h-4 sm:mt-1 w-8 h-8 text-blue-600",
  checkboxInvalid: "sm:w-4 sm:h-3 w-8 h-[14px] mt-2 ring-2 ring-red-500 text-blue-600 bg-gray-100 border-gray-300 rounded ",
}


export const cssControl = {
  // login css control
  css: 'bg-light-gray-3 p-2 rounded-lg border border-light-gray-4 w-full text-black-color-3 outline-none focus:bg-light-gray-3',
  validCss: 'bg-light-gray-3 p-2 rounded-lg border border-red-color-2 w-full text-black-color-3 outline-none focus:bg-light-gray-3',
  addCss: 'bg-light-gray-3 p-2 rounded-lg border border-light-gray-4 w-full text-black-color-3 resize-none outline-none',
  validAddCss: 'bg-light-gray-3 p-2 rounded-lg border border-red-color-2  w-full text-black-color-3 resize-none outline-none',

  // register css control 
  css2: ' w-full bg-gray-color-2 rounded-lg p-2 mid-xl:p-2.5 pl-3 cairoRegular text-white text-[15px] mid-xl:text-[20px] outline-none focus:bg-red-color focus:bg-opacity-[17%]',
  validCss2: ' w-full border border-red-color-2 bg-gray-color-2 rounded-lg p-2 mid-xl:p-2.5 pl-3 cairoRegular text-white text-[15px] mid-xl:text-[20px] outline-none focus:bg-red-color focus:bg-opacity-[17%]',
}


export const regex = {
  emailRegex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i,
  passRegex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i,
  mobilenoRegex: /^([0-9]{9})$/i,
  postCodeRegex: /^([0-9]{4})$/i,
  nameRegex: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/i,
}

export const states = [
  {
    name: "Australian Capital Territory"
  }, {
    name: "New South Wales"
  }, {
    name: "Northern Territory"
  }, {
    name: "Queensland"
  }, {
    name: "South Australia"
  }, {
    name: "Tasmania"
  }, {
    name: "Victoria"
  }, {
    name: "Western Australia"
  },
]

export const content = {
  tooltip: "Please select checkbox"
}


export const outlet = [
  {
    type: "Digital (Online, Podcast, etc.)",
  }, {
    type: "Freelance"
  }, {
    type: "Print - Magazine"
  }, {
    type: "Print Newspaper "
  }, {
    type: "Radio"
  }, {
    type: "Seven Network employee"
  }, {
    type: "Television"
  }, {
    type: "Other"
  },
]




export const SuccessModal = (title, description) => {

  Swal.fire({
    icon: 'success',
    iconColor: '#e74c3c',
    title: title,
    text: description,
    confirmButtonColor: '',
    confirmButtonText: 'OK',
    showClass: {
      popup: 'swal2-show',
      backdrop: 'swal2-backdrop-show ',
      icon: 'swal2-icon-show'
    },
    customClass: {
      confirmButton: "rounded-lg",
    }
  })
}




export const ErrorModal = (title, description) => {
  Swal.fire({
    icon: 'warning',
    iconColor: '#e74c3c',
    title: title,
    text: description,
    confirmButtonColor: '',
    confirmButtonText: 'OK',
    showClass: {
      popup: 'swal2-show',
      backdrop: 'swal2-backdrop-show',
      icon: 'swal2-icon-show'
    },
    customClass: {
      confirmButton: "rounded-lg",
    }
  })
}