import React from 'react'
import logo from '../Navbar/images/logo.png';
import hambger from '../Navbar/images/hambger-icon.svg'
import search from '../Navbar/images/feather-search-icon.svg'
import bellIcon from '../Navbar/images/bell-icon.svg'
// import redcross from '../Header/images/red-cross-icon.svg'

export default function NavBar() {
    return (
        <>
            <div className="flex w-full items-center flex-wrap justify-between border-b border-gray-3" >
                {/* <!-- logo --> */}
                <a href="/" >
                    <img src={logo} alt="logo" className="sm:max-w-[160px] max-w-[120px]" />
                </a>
                {/* <!-- logo End--> */}
                {/* <!--Menu- Start--> */}
                <div className="z-10 flex-1 xl:flex justify-end max-xl:hidden pr-6 mid-xl:pr-12" id="navbar-sticky">
                    <ul className="flex flex-col lg:flex-row lg:space-x-8 mid-xl:space-x-10 md:mt-0" >
                        <li>
                            <a href="/" className="nav-link" >Hi, Vikas</a>
                        </li>
                        <li>
                            <a href="/" className="nav-link">Shows</a>
                        </li>
                        <li>
                            <a href="/" className="nav-link">Genres</a>
                        </li>
                        <li>
                            <a href="/" className="nav-link">Schedule</a>
                        </li>
                        <li>
                            <a href="/" className="nav-link active">Administration Panel</a>
                        </li>
                    </ul>
                </div>
                {/* <!--Menu- End--> */}
                <div className="max-lg:flex-1 max-lg:justify-end flex md:gap-4 gap-3 items-center relative" >
                    {/* <!-- seacrh-box Start--> */}
                    <div className="search-box group">
                        <button type="button" className="search-btn bg-transparent sm:hidden px-0 py-6" >
                            <img src={search} className="w-6 h-6" alt="" />
                        </button>
                        <form
                            className="bg-black-3 sm:w-[clamp(15em,25vw,20rem)] group-hover:flex hidden w-64 rounded-3xl items-center sm:flex sm:static justify-between absolute top-[5.3rem] transition-all right-0 duration-300">
                            <input type="search" placeholder="Find movies, shows & more" className="w-full placeholder:text-gray-3 py-2 border-0 outline-none pl-6 text-gray-3 text-sm mid-xl:text-base bg-transparent" />
                            <button type="button" className="search-btn bg-transparent px-5 pr-5">
                                <img src={search} className="icons-img" alt="Search" />
                            </button>
                        </form>
                    </div>
                    {/* <!-- seacrh-box End--> */}
                    {/* <!-- Notificaton-ball Icon Start--> */}
                    <div className="notificaton-ball-icon group ">
                        <a href="/" className="px-7 py-3">
                            <img src={bellIcon} className=" mx-auto mid-xl:w-7 mid-xl:h-7 w-6 h-6" alt="" />
                        </a>
                        <div className="absolute transition-all duration-500 opacity-0 hidden top-18 bg-black-3 right-0 w-72 z-20 group-hover:block group-hover:opacity-100" >
                            <div className="flex items-center px-4 py-4 justify-between">
                                <h6 className="text-gray-3 font-medium">
                                    Notifications
                                </h6>
                                {/* <!-- <a href="/" className="text-red hover:underline">View All</a> --> */}
                            </div>
                            <ul className="overflow-y-auto max-h-96">
                                <li className="hover:bg-black-color-5 transition-all duration-300">
                                    <div className="relative py-4 px-3">
                                        <div className="flex flex-row">
                                            <div>
                                                <img
                                                    src="https://mdbootstrap.com/img/new/avatars/2.jpg"
                                                    className="rounded-full w-24"
                                                    alt="#"
                                                />
                                            </div>
                                            <div className="ml-3">
                                                <a
                                                    href="/"
                                                    className="transition duration-300 ease-in-out inline-block text-base text-offwhite mb-3"
                                                >New episodes
                                                    are added to the
                                                    schedule.</a>

                                                <span
                                                    className="text-sm text-gray-3"
                                                >2s ago</span>

                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li
                                    className="hover:bg-black-color-5 transition duration-300 ease-in-out"
                                >
                                    <div className="relative py-4 px-3">
                                        <div className="flex flex-row">
                                            <div>
                                                <img
                                                    src="https://mdbootstrap.com/img/new/avatars/2.jpg"
                                                    className="rounded-full w-24"
                                                    alt="#"
                                                />
                                            </div>
                                            <div className="ml-3">
                                                <a
                                                    href="/"
                                                    className="inline-block text-base text-offwhite mb-3"
                                                >New episodes
                                                    are added to the
                                                    schedule.</a>

                                                <span
                                                    className="text-sm text-gray-3"
                                                >2s ago</span>

                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li
                                    className="hover:bg-black-color-5 transition duration-300 ease-in-out"
                                >
                                    <div className="relative py-4 px-3">
                                        <div className="flex flex-row">
                                            <div>
                                                <img
                                                    src="https://mdbootstrap.com/img/new/avatars/2.jpg"
                                                    className="rounded-full w-24"
                                                    alt="#"
                                                />
                                            </div>
                                            <div className="ml-3">
                                                <a
                                                    href="/"
                                                    className="inline-block text-base text-offwhite mb-3"
                                                >New episodes
                                                    are added to the
                                                    schedule.
                                                </a>

                                                <span
                                                    className="text-sm text-gray-3"
                                                >2s ago</span>

                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>
                    {/* <!-- Notificaton-ball Icon End--> */}
                    {/* <!-- Sidebar Menu Inculded in Main Menu main Menu show only mobile screen End--> */}
                    {/* <!-- Hambager-toggle-Sidebar-icon Start--> */}
                    <div className="Sidebar-menu">
                        {/* <!-- Hambager--icon Start--> */}
                        <button
                            onclick="onToggleMenu(this)"
                            name="menu"
                            className="inline-flex items-center p-2 order-last toggle-btn"
                        >
                            <img
                                alt="hambger"
                                src={hambger}
                                className="mid-xl:w-7 mid-xl:h-7 w-5 h-5"
                            />
                        </button>
                        <div className="side-bar backdrop-blur-md">
                            <ul
                                className="p-10 bg-black-3 overflow-y-auto"
                            >
                                <li>
                                    <a
                                        href="/"
                                        className="nav-link xl:hidden"
                                    >Hi, Vikas</a>

                                </li>
                                <li>
                                    <a
                                        href="/"
                                        className="nav-link xl:hidden"
                                    >Shows</a>

                                </li>
                                <li>
                                    <a
                                        href="/"
                                        className="nav-link xl:hidden"
                                    >Genres</a>

                                </li>
                                <li>
                                    <a
                                        href="/"
                                        className="nav-link xl:hidden"
                                    >Schedule</a>

                                </li>
                                <li>
                                    <a
                                        href="/"
                                        onclick="sub_menu_toggle(this)"
                                        className="nav-link lg:hidden"
                                    >Administration Panel
                                        <span
                                        ><i
                                            className="fa-solid fa-angle-down float-right mt-2 text-red-2"
                                        ></i></span>
                                    </a>
                                    <ul
                                        className="hidden sub-dropdown [&_a]:py-1 p-4 py-2 bg-black-color-3"
                                    >
                                        <li>
                                            <a
                                                href="/"
                                                className="nav-link"
                                            >Dashboard</a>

                                        </li>
                                        <li>
                                            <a
                                                href="/"
                                                className="nav-link"
                                            >Show</a>

                                        </li>
                                        <li>
                                            <a
                                                href="/"
                                                className="nav-link"
                                            >Events</a>

                                        </li>
                                        <li>
                                            <a
                                                href="/"
                                                className="nav-link"
                                            >News</a>

                                        </li>
                                        <li>
                                            <a
                                                href="/"
                                                className="nav-link"
                                            >Users</a>

                                        </li>
                                        <li>
                                            <a
                                                href="/"
                                                className="nav-link"
                                            >Groups</a>

                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="/" className="nav-link"
                                    >Edit Profile</a>

                                </li>
                                <li>
                                    <a href="/" className="nav-link"
                                    >Help</a>

                                </li>
                                <li>
                                    <a href="/" className="nav-link"
                                    >Publicity Contacts</a>

                                </li>
                                <li>
                                    <a href="/" className="nav-link"
                                    >Logout</a>

                                </li>
                            </ul>
                            <button
                                onclick="onToggleMenu(this)"
                                name="close"
                                className="absolute top-5 right-5 transition-all duration-300"
                            >
                                <img
                                    alt='cross'
                                    src="img/red-cross-icon.svg"
                                    className="w-5 h-5"
                                />
                            </button>
                        </div>
                        {/* <!-- Hambager-icon -End--> */}
                    </div>
                    {/* <!-- Hambager-toggle-Sidebar-icon End--> */}
                    {/* <!-- Sidebar Menu Inculded in Main Menu main Menu show only mobile screen End--> */}
                </div>
            </div>
        </>

    )
}



{/* 
function Notifications() {
    return (
        <li className="hover:bg-black-color-5 transition duration-300 ease-in-out">
            <div className="relative py-4 px-3">
                <div className="flex flex-row">
                    <div>
                        <img src="https://mdbootstrap.com/img/new/avatars/2.jpg" className="rounded-full w-24" alt="#" />
                    </div>
                    <div className="ml-3">
                        <a href="/" className="transition duration-300 ease-in-out inline-block text-base text-offwhite mb-3">New episodes are added to the schedule.</a>
                        <span className="text-sm text-gray-3">2s ago</span>
                    </div>
                </div>
            </div>
        </li>
    )

} */}
