import React, { useState } from 'react'
import Footer from '../components/common/Footer'
import logo from '../components/Register/images/logo.png'
import seven from '../components/Register/images/assets-2.webp'
import '../components/Register/Register.css'
import RegisterForm from '../components/Register/RegisterForm'
import axios from 'axios'
import { path } from '../routes/path'
import Swal from 'sweetalert2'
import { useNavigate, Link } from 'react-router-dom'
import confirmIcon from "../components/Register/images/confirm.png"
import { ErrorModal } from '../helper/Helper'
import { FullScreenLoader } from '../../src/components/common/FullScreenLoader'
import PrivacyPolicy from '../components/common/PrivacyPolicy'

export default function Register() {

	const Navigate = useNavigate();

	const [isLoader, setIsLoader] = useState(false)
	const [newModal, setNewModal] = useState(false)


	async function signInHandler(firstname, lastname, email, password, organisation, position, mobileNo, sevenNtkContent, outlet, address, suburb, state, postcode) {
		setIsLoader(true)
		await axios.post(path.register, {
			firstName: firstname,
			lastName: lastname,
			email: email,
			password: password,
			organization: organisation,
			position: position,
			mobileNumber: mobileNo,
			sevenNetworkContact: sevenNtkContent,
			outletType: outlet,
			address: address,
			suburb: suburb,
			state: state,
			postCode: postcode,
		}).then((response) => {
			console.log(response)
			setIsLoader(false)
			SuccessModalWithRedirect("Thanks for your information", "Your application is under review. You will hear back from us shortly.", "/")
		}).catch((error) => {
			// console.log(error)
			setIsLoader(false)

			ErrorModal(error?.response?.data?.message || error?.message || "Please contact site Admin", "Something went wrong")

		})

	}

	const SuccessModalWithRedirect = (title, description, pathname) => {
		Swal.fire({
			imageUrl: `${confirmIcon}`,
			imageWidth: 80,
			imageHeight: 80,
			title: title || "Sucessfull",
			text: description || "",
			confirmButtonText: 'OK',
			confirmButtonColor: '#E4202E',
			showClass: {
				popup: 'swal2-show',
				backdrop: 'swal2-backdrop-show',
			},
		}).then((result) => {
			if (result.isConfirmed) {
				Navigate(pathname)
			}
		})
	}


	return (

		<>
			{isLoader ? <FullScreenLoader /> : " "}
			{(newModal) ? <PrivacyPolicy closeModal={setNewModal} /> : ""}
			<section className=''>
				<main className="flex bg-[url('components/Register/images/Assets-1.webp')] bg-[right_bottom] lg:bg-[length:20rem] bg-[length:0rem] bg-no-repeat min-h-screen cairoRegular ">
					{/* <!-- left-red-box --> */}
					<div className="scheudle-red-box"></div>
					{/* <!-- left-red-box End --> */}
					<div className="relative overflow-x-clip block w-full">
						<div className="sm:max-w-[95%] mx-auto px-4">
							{/* <!-- Navigation -Menu Start--> */}
							<nav id="top-menu" className="sticky z-30 top-0 bg-white">
								<div className="flex w-full items-center flex-wrap justify-between border-b-2 border-slate-300 py-1">
									<Link to="/"><img src={logo} alt="logo" className="sm:max-w-[160px] max-w-[120px]" /></Link>
									{/* <!-- Collapsible wrapper --> */}
									{/* <!-- Right elements --> */}
									<div className="relative flex items-center">
										{/* <!-- Icon --> */}
										<div className="login-text sm:text-base text-[15px]">
											Already have an account?
											<Link to="/" className="text-red-color-2 max-sm:block max-sm:text-end"> Login Here</Link>
										</div>
									</div>
									{/* <!-- Right elements --> */}
								</div>
							</nav>
							{/* <!-- Navigation -Menu End--> */}
							<div className="user-registration md:my-5">
								<div className="grid grid-cols-2 gap-4">
									<div className="registration-form cairoRegular col-span-2 lg:col-span-1 max-lg:order-last">
										<h1 className="xl:text-5xl lg:text-4xl sm:text-3xl text-3xl py-4"><span className="font-bold">User</span> Registration</h1>
										{/* <!-- registration-form Start--> */}
										<RegisterForm open={setNewModal} signIn={signInHandler} />
										{/* <!-- registration-form End--> */}
									</div>
									{/* <!-- -Image-Right-section Start--> */}
									<div className="registration-right-image col-span-2 lg:col-span-1 max-sm:my-6">
										<div className="channel_7img lg:mt-[6rem] 2xl:flex max-mid-xl:text-center 2xl:flex-row-reverse max-mid-xl:flex-col-reverse max-mid-xl:items-center 2xl:items-center 2xl:justify-center">
											<img src={seven} alt="seven" className="max-mid-xl:mx-auto lg:max-w-[60%] max-w-[17rem]" />
											<h2 className="font-bold xl:text-5xl 2xl:-mr-[180px] 2xl:text-6xl 4xl:text-7xl relative">SINGLE PLATFORM TO
												<span className="text-red-color-2"> SCREEN</span> ALL</h2>
										</div>
									</div>
									{/* <!-- -Image-Right-section End--> */}
								</div>
								{/* bg-[url('components/Register/images/assets-2.webp' */}
							</div>
						</div>
					</div>
				</main>
				<Footer open={setNewModal} />
			</section>
		</>
	)
}
