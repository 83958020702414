const initialState = {
	isLoggedIn: false,
	token: null,
};


function authReducer(state = initialState, action) {
	switch (action.type) {
		case 'LOGIN_SUCCESS':
			return {
				...state,
				token: action.payload,
				isLoggedIn: true,
			};
		// case 'LOGOUT':
		//   return {
		//     ...state,
		//     isLoggedIn: false,
		//     user: null,
		//     error: null,
		//   };
		default:
			return state;
	}
}

export default authReducer