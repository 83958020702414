import React from 'react'
import ResetPasswordForm from '../../src/components/Reset-password/ResetPasswordForm'
import Slider from '../components/common/Slider'
import axios from 'axios'
import { path } from '../routes/path'

export default function ResetPassword() {

    async function resetpasswordHandler(password) {
        await axios.put(path.resetpassword, {
            password: password
        }).then((response) => {
            console.log(response)
        }).catch((error) => {
            console.log(error)
        })
    }
    return (
        <>
            {/* <img className='top-red-corner z-50' src={RedCorner} alt="" /> */}
            <div className='min-h-screen bg-black-color flex items-center p-8 sm:p-10 lg:p-14 mid-xl:p-16 bg-[url("components/Login/images/redCorner.svg")] bg-[left_top] mid-xl:bg-[length:12rem] md:bg-[length:8rem] bg-[length:30%] bg-no-repeat '>
                <div className='basis-full flex lg:flex-row flex-col gap-4 lg:gap-2 '>
                    <div className='slider-block'>
                        <Slider />
                    </div>

                    <div className='basis-5/12 flex items-center'>
                        <ResetPasswordForm reset={resetpasswordHandler} />
                    </div>
                </div>
            </div>



            {/* footer */}
            <div className='w-full flex  justify-center fixed bottom-0 cairoRegular text-[12px] sm:text-[14px] mid-xl:text-[18px] bg-dark-gray py-2.5 mid-xl:py-4'>
                <span className="copy-rights font-normal text-gray-color">Copyright @2023
                    <a href="/seven-network" className="text-red-color"> Seven Network</a>
                    , Inc
                    <a href="/privacy-policy" className="text-red-color"> | Privacy policy</a>
                </span>
            </div>
        </>
    )
}
