import React from 'react'
import { Icon } from '@iconify-icon/react'

export default function AddUserModel({ closeModal }) {

    function CloseMe() {
        closeModal(false)
    }
    return (
        <>
            <div class="custom-backdrop">
                <div class="popup_bg">
                    <div class="lg-popup">
                        <div class="flex justify-between items-start">
                            {/* <!-- Create New User Title --> */}
                            <h4 class="text-black font-bold mb-2">Create a New User</h4>
                            <button onClick={() => CloseMe()} class="text-gray-3 border-0 outline-0 text-2xl"><Icon icon="system-uicons:cross" className='icon-color' width={30} height={30} /></button>
                            {/* <!-- Create New User Form Field --> */}
                        </div>
                        <form>
                            <div class="grid grid-cols-2 gap-4 border-b-2 border-red-2 sm:py-10 py-4">
                                <div class="col-span-2 md:col-span-1">
                                    <label for="name" class="text-gray-3 text-base">First Name</label>
                                    <input required="" type="name" class="custom-input" placeholder="" />
                                </div>
                                <div class="col-span-2 md:col-span-1">
                                    <label for="l-name" class="text-gray-3 text-base">Last Name</label>
                                    <input required="" type="l-name" class="custom-input" placeholder="" />
                                </div>
                                <div class="col-span-2 md:col-span-1">
                                    <label for="email" class="text-gray-3 text-base ">Email Address</label>
                                    <input required="" type="email" id="email" name="email" class="custom-input {{  error  ?  'text-red-600'  :  'text-green-600'  }}" placeholder="" />
                                </div>
                                <div class="col-span-2 md:col-span-1">
                                    <label for="company" class="text-gray-3 text-base">Company</label>
                                    <input required="" type="text" id="company" name="company" class="custom-input" placeholder="" />
                                </div>

                                <div class="col-span-2 md:col-span-1">
                                    <label for="position" class="text-gray-3 text-base">Position</label>
                                    <input required="" type="text" name="position" class="custom-input" placeholder="" />
                                </div>
                                <div class="col-span-2 md:col-span-1">
                                    <label for="o-type" class="text-gray-3 text-base">Outlet Type</label>
                                    <select
                                        class="bg-white-1 p-2 outline-none rounded-lg border-solid border-[#DCDCDC] border w-full text-[#323232]">
                                        <option value="tele">Television</option>
                                    </select>
                                </div>
                            </div>
                            <div class="border-b-2 border-red-2 border-solid sm:py-10 py-4">
                                <h5 class="text-gray-3 sm:pb-8 max-sm:py-4">User Group</h5>
                                <div class="grid sm:grid-cols-3 [&_label]:sm:text-base [&_label]:text-[14px]">
                                    <div class="col-span-2 lg:col-span-1 mb-[1px]">
                                        <label for="" class="flex items-center text-gray-3">
                                            <input type="checkbox" id="" class="custom-chekbox bg-white" /> Adelaide-Media
                                        </label>
                                        <label for="" class="flex items-center text-gray-3">
                                            <input type="checkbox" id="" class="custom-chekbox bg-white" /> Adelaide-Seven
                                            Staff
                                        </label>
                                        <label for="" class="flex items-center text-gray-3">
                                            <input type="checkbox" id="" class="custom-chekbox bg-white" /> Brisbane-Media
                                        </label>
                                        <label for="" class="flex items-center text-gray-3">
                                            <input type="checkbox" id="" class="custom-chekbox bg-white" /> Brisbane-Seven
                                            Staff
                                        </label>
                                        <label for="" class="flex items-center text-gray-3">
                                            <input type="checkbox" id="" class="custom-chekbox bg-white" /> FWAW VVVIP Media-FWAW VVIP Media
                                        </label>
                                        <label for="" class="flex items-center text-gray-3">
                                            <input type="checkbox" id="" class="custom-chekbox bg-white" /> Melbourne-Media
                                        </label>
                                    </div>
                                    <div class="col-span-2 lg:col-span-1 mb-[1px]">
                                        <label for="" class="flex items-center text-gray-3">
                                            <input type="checkbox" id="" class="custom-chekbox bg-white" /> National-HAA VIP
                                            LIST
                                            2022
                                        </label>
                                        <label for="" class="flex items-center text-gray-3">
                                            <input type="checkbox" id="" class="custom-chekbox bg-white" /> National-Media
                                            VIP
                                            (all shows)
                                        </label>
                                        <label for="" class="flex items-center text-gray-3">
                                            <input type="checkbox" id="" class="custom-chekbox bg-white" /> National-MKR
                                            2022
                                            Grand Final
                                        </label>
                                        <label for="" class="flex items-center text-gray-3">
                                            <input type="checkbox" id="" class="custom-chekbox bg-white" /> National-SAS
                                            Australia podcast
                                        </label>
                                        <label for="" class="flex items-center text-gray-3">
                                            <input type="checkbox" id="" class="custom-chekbox bg-white" /> National-Staff
                                            VIP
                                            (all shows)
                                        </label>
                                        <label for="" class="flex items-center text-gray-3">
                                            <input type="checkbox" id="" class="custom-chekbox bg-white" /> National-Stellar
                                        </label>
                                    </div>
                                    <div class="col-span-2 lg:col-span-1 mb-[1px]">
                                        <label for="" class="flex items-center text-gray-3">
                                            <input type="checkbox" id="" class="custom-chekbox bg-white" /> National-TV
                                            Guides -
                                            VIPS
                                        </label>
                                        <label for="" class="flex items-center text-gray-3">
                                            <input type="checkbox" id="" class="custom-chekbox bg-white" /> Perth-HAA Perth
                                        </label>
                                        <label for="" class="flex items-center text-gray-3">
                                            <input type="checkbox" id="" class="custom-chekbox bg-white" /> Perth-Media
                                        </label>
                                        <label for="" class="flex items-center text-gray-3">
                                            <input type="checkbox" id="" class="custom-chekbox bg-white" /> Perth-Seven
                                            Staff
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-2 gap-4 sm:py-10 py-4">
                                <div class="col-span-2 md:col-span-1">
                                    <label for="m-number" class="text-gray-3 text-base">Mobile Phone</label>
                                    <input required="" type="number" name="m-number" class="custom-input" placeholder="" />
                                </div>

                                <div class="col-span-2 md:col-span-1">
                                    <label for="d-m-number" class="text-gray-3 text-base">Daytime Phone</label>
                                    <input required="" type="number" name="d-m-number" class="custom-input" placeholder="" />
                                </div>

                                <div class="col-span-2 md:col-span-1">
                                    <label for="c-pass-word" class="text-gray-3 text-base">Address</label>
                                    <textarea rows="1" cols="2" name="c-pass-word" class="custom-input py-2"></textarea>
                                </div>
                                <div class="col-span-2 md:col-span-1">
                                    <label for="suburb" class="text-gray-3 text-base">Suburb</label>
                                    <input required="" type="text" name="suburb" class="custom-input" placeholder="" />
                                </div>
                                <div class="col-span-2 md:col-span-1">
                                    <label for="state" class="text-gray-3 text-base">State</label>
                                    <select
                                        class="bg-white-1 p-2 outline-none rounded-lg border-solid border-[#DCDCDC] border w-full text-[#323232]">
                                        <option value="south">New South Wales</option>
                                    </select>
                                </div>
                                <div class="col-span-2 md:col-span-1">
                                    <label for="p-code" class="text-gray-3 text-base">Postcode</label>
                                    <input required="" type="number" pattern="[0-9]{1,4}" max="9999" min="1"
                                        class="custom-input" placeholder="" />
                                </div>
                            </div>
                            <div>
                                <button type="submit" class="red-btn">
                                    SEND INVITE</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
