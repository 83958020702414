import { createColumnHelper } from '@tanstack/react-table'
import React from 'react'
import AllDownload from '../../../assets/images/all-download-history-icon.svg';
import History from '../../../assets/images/download-history-icon.svg'
import Table from '../../common/Table';
import MailIcon from '../../../assets/images/mail-all.svg';
import WatchIcon from '../../../assets/images/watch-icon.svg'
import LockIcon from '../../../assets/images/lock-icon.svg';
import DeleteIcon from '../../../assets/images/delete-icon.svg';
import SearchIcon from '../../Admin-Dashboard/Navbar/images/feather-search-icon.svg'



export default function UserAdmin() {
    const Users = [
        {
            FirstName: '77CM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        },
        {
            FirstName: '4BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '0BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '2BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        },
        {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        },
        {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'hello',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        }, {
            FirstName: '7BCM',
            LastName: ' MultiMedia',
            Company: 'Seven',
            Registration: '13 May 2022',
            EmailAddress: '7BCMMultiMedia@Seven.com.au',
            Status: 'Approved',
            Actions: ''
        },
    ]

    const columnHelper = createColumnHelper()
    const columns = [
        columnHelper.accessor('FirstName', {
            cell: (info) => <div className='flex '>
                <label className="flex items-center">
                    <input type="checkbox" id="ser-number" className="custom-chekbox" />
                </label>
                <span>{info.getValue()}</span>
            </div>
            ,
            header: () =>
                // <span className='flex'>
                <label className="flex items-center">
                    <input type="checkbox" id="ser-number" className="custom-chekbox" />
                    First Name
                </label>
            ,

        }),
        columnHelper.accessor('LastName', {
            cell: (info) =>
                <span>{info.getValue()}</span>
            ,
            header: () => <span className=''>Last Name</span>,

        }),
        columnHelper.accessor('Company', {
            header: () => 'Company',
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('Registration', {
            header: () => <span>Registration</span>,
        }),
        columnHelper.accessor('EmailAddress', {
            header: () => <span>Email Address</span>,
        }),
        columnHelper.accessor('Status', {
            header: () => <span>Status</span>,
        }),
        columnHelper.accessor('Actions', {
            cell: (info) => <div className='flex space-x-3 2xl:space-x-6 items-center'>
                <>
                    <button className="icons-img">
                        <img src={MailIcon} title="All Mail" alt="" />
                    </button>
                    <button className="icons-img">
                        <img src={WatchIcon} title="Time" alt="" />
                    </button>
                    <button className="icons-img">
                        <img src={LockIcon} title="Lock" alt="" />
                    </button>
                    <button className="icons-img">
                        <img src={DeleteIcon} title="Delete" alt="" />
                    </button>
                </>
                <span>{info.getValue()}</span>
            </div>
            ,
            header: () => <span className=''>Actions</span>,

            // header: () => <span>Actions</span>,
        }),
    ]
    return (
        <>
            <div className="py-3 max-xl:hidden ">
                <div className="flex justify-between lg:items-center">
                    <div className="lg:flex 2xl:space-x-7 lg:space-x-2  space-x-0">
                        <div className="flex lg:space-x-4 space-x-2 [&_label]:flex">
                            <label className="text-gray-3 text-sm 2xl:text-lg items-center">
                                <input type="checkbox" id="f-name" className="custom-chekbox" /> Registered
                            </label>
                            <label className="text-gray-3 text-sm 2xl:text-lg items-center">
                                <input type="checkbox" id="f-name" className="custom-chekbox" />Awaiting Confirmation
                            </label>
                            <label className="text-gray-3 text-sm 2xl:text-lg items-center">
                                <input type="checkbox" id="f-name" className="custom-chekbox" /> Approved
                            </label>
                        </div>
                        <div className="flex 2xl:space-x-4 space-x-3 ">
                            {/* class="flex lg:space-x-4 space-x-3"> */}
                            <div className="max-lg:pt-4 w-[150px] 3xl:w-[250px]">
                                <button id="account-btn"
                                    type="button"
                                    className="w-full flex items-center justify-between border outline-0 border-gray-3 rounded-xl px-3 py-1 text-gray-3 text-sm 2xl:text-base bg-black-color-3 sm:leading-6">
                                    <span className="block truncate">All Accounts</span>
                                    <i className="fa-solid fa-chevron-down "></i>
                                </button>
                                <div id="account-box" className="relative">
                                    <ul className="account-box hidden opacity-90 w-full absolute z-10 mt-2 backdrop-blur-xl max-h-80
                                            last:overflow-auto rounded-xl bg-black-color-3 p-2 text-base border-gray-3">
                                        <li data-value="option1"
                                            className="text-gray-color-5 hover:text-white relative cursor-default select-none p-2 select-box__option">
                                            All Accounts</li>
                                        <li data-value="option2"
                                            className="text-gray-color-5 hover:text-white relative cursor-default select-none p-2 select-box__option">
                                            Locked Accounts</li>
                                        <li data-value="option3"
                                            className="text-gray-color-5 hover:text-white relative cursor-default select-none p-2 select-box__option">
                                            Unlocked Accounts</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="max-lg:pt-4 w-[150px] 3xl:w-[250px]">
                                <button id="user-btn"
                                    className="w-full flex items-center justify-between relative  border outline-0 border-gray-3 rounded-xl px-3 py-1 text-gray-3 text-sm 2xl:text-base bg-black-color-3 sm:leading-6">
                                    <span className="block truncate">All User Groups</span>
                                    <i className="fa-solid fa-chevron-down"></i>
                                </button>
                                <div id="user-box" className="relative">
                                    <ul className="account-box hidden opacity-90 w-full absolute z-10 mt-2 backdrop-blur-xl max-h-80
                            last:overflow-auto rounded-xl bg-black-color-3 p-2 text-base border-gray-3 border">
                                        <li data-value="option1"
                                            className="text-gray-color-5 hover:text-white relative  select-none p-2 select-box__option">
                                            All User Groups</li>
                                        <li data-value="option2"
                                            className="text-gray-color-5 hover:text-white relative  select-none p-2 select-box__option">
                                            Adelaide - Media</li>
                                        <li data-value="option3"
                                            className="text-gray-color-5 hover:text-white relative  select-none p-2 select-box__option">
                                            Adelaide - Seven Staff</li>
                                        <li data-value="option4"
                                            className="text-gray-color-5 hover:text-white relative  select-none p-2 select-box__option">
                                            Brisbane - Media</li>
                                    </ul>
                                </div>
                            </div>
                            <button
                                className="text-red-color-2 mid-xl:text-xl text-base max-lg:pt-5  underline hover:no-underline font-medium">
                                Update
                            </button>
                        </div>
                    </div>

                    {/* <!-- History-Download Btn Start--> */}
                    <div>
                        <button onclick="history_dropdown(this)" className="text-gray-3 xl:hidden text-xl download-history-btn">
                            <i className="fa-solid fa-ellipsis-vertical text-2xl"></i>
                        </button>
                        <ul className="d-history-box xl:flex xl:space-x-2 max-sm:space-y-2 xl:static
                         absolute hidden right-10 max-xl:bg-black-color-3 rounded-lg max-xl:p-4 max-xl:backdrop-blur-xl max-xl:opacity-90">
                            <li>
                                <a href="/"
                                    className="space-x-2 text-offwhite sm:text-gray-3 text-sm 2xl:text-base flex items-center hover:text-red">
                                    <img src={AllDownload}
                                        title="All Download" alt="#" />
                                    <span>Download All Users History</span> </a>
                            </li>
                            <li>
                                <a href="/" className="space-x-2 text-offwhite sm:text-gray-3 text-sm 2xl:text-base items-center flex hover:text-red">
                                    <img src={History}
                                        title="Download History" alt="#" />
                                    <span>Download User List</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="recent-videos-list lg:my-10 md:my-8 my-6 ">
                <div className="list-title mb-5 flex justify-between items-center" >
                    <h4 className="text-offwhite text-base sm:text-2xl lg:text-3xl">Users</h4>
                    {/* <!--seacrh - box Start-- > */}
                    <form
                        className="bg-black-3 rounded-3xl border-1 items-center hidden sm:flex sm:justify-between">
                        <input type="search" placeholder="Search"
                            className=" placeholder:text-gray-3 focus-visible:border-0 py-1.5 2xl:py-2 border-0 outline-none pl-6 text-gray-3 text-sm mid-xl:text-base bg-transparent" />
                        <button className="search-btn bg-transparent sm:px-5 pr-5">
                            <img src={SearchIcon} alt="search" className="w-5 h-5" /></button>
                    </form>
                    {/* <!--seacrh - box End-- > */}
                </div >
                <Table value={Users} columns={columns} />
            </div>
        </>
    )
}
