import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer({ open }) {
	return (
		<footer className="bg-dark-gray p-3">
			<div className="footer-menu text-center">
				<p className="copy-rights  text-[12px] sm:text-[14px] mid-xl:text-[18px] font-normal text-gray-color ">Copyright @2023
					<Link to="/seven-network" className="text-red-color-2">Seven Network</Link>, Inc <button onClick={() => open(true)} className="text-red-color-2">| Privacy policy</button></p>
			</div>
		</footer>
	)
}
