
const Actions = {
  registerSuccess: (token) => ({
    type: 'REGISTER_SUCCESS',
    payload: token,
  }),

  // registerFailure: (error) => ({
  //   type: 'REGISTER_FAILURE',
  //   payload: error,
  // }),

  loginSuccess: (token) => ({
    type: 'LOGIN_SUCCESS',
    payload: token,
  }),

  // loginFailure: (error) => ({
  //   type: 'LOGIN_FAILURE',
  //   payload: error,
  // }),

  logout: () => ({
    type: 'LOGOUT',
  })
}


export default Actions;
