import React, { useState } from 'react'
import Navbar from '../components/Admin-Dashboard/Navbar/Nav'
import SubHeader from '../components/Admin-Dashboard/Header/SubHeader'
import Footer from "../components/common/Footer"
import SideBar from '../components/common/SideBar'
import { Outlet } from 'react-router-dom'
import AddUserModel from '../components/Admin-Dashboard/Pop-Ups/AddUserModel'
import PrivacyPolicy from '../components/common/PrivacyPolicy'

export default function Dashboard() {
    const [addUser, setAddUser] = useState(false)
    const [newModal, setNewModal] = useState(false)

    return (
        <>
            {(newModal) ? <PrivacyPolicy closeModal={setNewModal} /> : ""}
            <section>
                {(addUser) ? <AddUserModel closeModal={setAddUser} /> : ""}
                <main className="flex bg-black min-h-screen ">
                    {/* <!--scheudle-Box left-red-box Start--> */}
                    <SideBar />
                    {/* <!--scheudle-Box left-red-box End--> */}
                    <div className="relative overflow-x-clip block w-full  px-2">
                        <div className="sm:max-w-[95%] mx-auto px-4 ">

                            <nav id="top-menu" className="sticky z-30 top-0 bg-black">
                                {/* <!-- Sub-Navigation -Menu Start--> */}
                                <Navbar />
                                {/* <!-- Navigation -Menu End--> */}

                                {/* <!-- Navigation -Menu Start--> */}
                                <SubHeader open={setAddUser} />
                                {/* <!-- Sub-Navigation -Menu End--> */}
                            </nav>

                            {/* <!-- Recent-videos-list Start--> */}
                            <Outlet />
                            {/* <!-- recent-videos-list End--> */}
                        </div>
                    </div>
                </main>
                <Footer open={setNewModal} />
            </section >
        </>
    )
}

