import React from 'react'
import { Icon } from '@iconify-icon/react';


export default function PrivacyPolicy({ closeModal }) {

    function CloseMe() {
        closeModal(false)
    }
    return (
        <>

            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 px-10  sm:px-0 ">
                <div
                    className="relative bg-offwhite md:p-8 mid-xl:p-10 p-6 rounded-3xl sm:max-w-[calc(55%+.5rem)] w-full mx-auto [&_p]:text-gary-4 [&_p]:py-2 ">
                    <button onClick={() => { CloseMe() }} className='absolute  top-4 md:top-6 right-4 md:right-7'
                    ><Icon icon="system-uicons:cross" className='icon-color' width={30} height={30} /></button>
                    {/* <!-- Trems Conditions Tittle Start--> */}
                    <h4 className=" text-black font-bold mb-2 w-full text-2xl lg:text-3xl ">Privacy policy</h4>
                    {/* <!-- Trems Conditions Tittle End --> */}
                    <div className="overflow-y-auto mid-xl:h-[max(10vw,30rem)] h-[max(10vw,18rem)]">
                        <div className="policy-content my-6 text-sm mid-xl:text-base">
                            <h5 className="my-3 font-medium text-xl">Availability and accuracy of the service</h5>
                            <p >Seven will try to make the Video Service available to you at all times but sometimes
                                availability is affected by factors outside our control. For this reason we can't guarantee
                                that the Video Service will be available to you at all times. It may also be necessary to
                                interrupt the service occasionally in order to upgrade or maintain it.</p>
                            <p>Streaming quality will vary depending on broadband connection speed. File sizes for publicity
                                material downloads can vary depending on type of content, and other factors such as network
                                traffic are also relevant.</p>
                            <p>You are responsible for paying for your personal broadband connection, including any download
                                charges. Data usage will depend on the length and quality of content streamed.</p>
                        </div>
                        <div className="policy-content my-6 text-sm mid-xl:text-base">
                            <h5 className="my-3 font-medium text-xl">Restrictions on use – intellectual property rights</h5>
                            <p>7 Screening Room includes materials owned by Seven as well as materials owned by third
                                parties, in which case they have been posted on 7 Screening Room by virtue of a licence,
                                grant or some other form of agreement between the third party and Seven.</p>
                            <p>All information, text, material, graphics, software and advertisements on 7 Screening Room
                                ("Content") are protected by Australian and international copyright and trademark laws.</p>
                            <p>Apart from any use permitted under the Copyright Act 1968 (Cth) you must not modify, copy,
                                reproduce, republish, frame, upload to a third party, post, transmit or distribute this
                                Content in any way except as expressly provided for on 7 Screening Room or expressly
                                authorised in writing by Seven.</p>
                            <p>The reproduction, duplication, distribution (including by way of email, facsimile or other
                                electronic means), publication, modification, copying or transmission of material from 7
                                Screening Room is STRICTLY PROHIBITED unless you have obtained the prior written consent of
                                Seven or unless it is specifically stated otherwise within 7 Screening Room. The material
                                covered by this prohibition includes, without limitation, any text, graphics, logos,
                                photographs, audio or video material or stills from audiovisual material available on 7
                                Screening Room.</p>
                            <p>7 Screening Room contains links to other websites outside 7 Screening Room for your
                                information and convenience. Seven makes no warranty or representation as to the
                                functionality of these links, the security of those sites or the reliability or accuracy of
                                the information contained on these sites. You access any such website entirely at your own
                                risk.</p>
                            <p>Strictly on the condition that you keep all Content intact and in the same form as presented
                                on 7 Screening Room (including without limitation all copyright, trademark and other
                                proprietary notices and all advertisements), you may ONLY view the Content for business
                                purposes to preview upcoming programming.</p>
                        </div>
                        <div className="policy-content my-6 text-sm mid-xl:text-base">
                            <h5 className="my-3 font-medium  text-xl">Confidentiality</h5>
                            <p>Authorised users are not to provide their individual login username and password to any other
                                person to access 7 Screening Room.</p>
                        </div>
                        <div className="policy-content my-6 text-sm mid-xl:text-base">
                            <h5 className="my-3 font-medium text-xl">Disclaimers and limitation of liability</h5>
                            <p>Authorised users are not to provide their individual login username and password to any other
                                person to access 7 Screening Room.</p>
                            <p>Seven does not warrant that 7 Screening Room is fit for any particular purpose; that the
                                functions contained in the materials in 7 Screening Room will be uninterrupted; that defects
                                will be corrected; that 7 Screening Room is free of viruses and other harmful components or
                                that the Content is accurate, complete or reliable.</p>
                            <p>You acknowledge that Seven and its parents and related parties together with their respective
                                employees, agents, directors, officers and shareholders, are not liable for any delays,
                                inaccuracies, failures, errors, omissions, interruptions, deletions, defects, viruses,
                                communication line failures or for the destruction, damage or unauthorized access to your
                                computer system or Site.</p>
                            <p>You acknowledge that Seven is not liable for any defamatory, offensive or illegal conduct or
                                material found in connection with 7 Screening Room, including such conduct or material
                                transmitted by any means by any other person.</p>
                            <p>You acknowledge that Seven is not liable for any damages, including, without limitation,
                                direct, incidental, special, consequential or punitive damages, in connection with or
                                arising from your use or from your inability to use 7 Screening Room.</p>
                            <p>You expressly acknowledge and agree that Seven does not exert control over users of 7
                                Screening Room (including individuals referred to on 7 Screening Room as guests and experts)
                                and is not liable either for their opinions or their behaviour including any information,
                                advice, defamatory statements or offensive conduct.</p>
                        </div>
                        <div className="policy-content my-6 text-sm mid-xl:text-base">
                            <h5 className="my-3 font-medium text-xl">Contact Us</h5>
                            <p>If you have any feedback you wish to provide Seven in relation to this Site, you should go to
                                the “Contact Us” link on 7 Screening Room. When you “contact us”, you will be asked to
                                provide personal information which Seven will handle in accordance with its Privacy Policy.
                            </p>
                        </div>
                        <div className="policy-content my-6 text-sm mid-xl:text-base">
                            <h5 className="my-3 font-medium text-xl">Termination</h5>
                            <p>The Terms of Use are effective until terminated by Seven. Seven may terminate this agreement
                                and your access to 7 Screening Room at any time without notice (including, for example, if
                                you have infringed copyright in materials on 7 Screening Room or have shared your username
                                and password with a third party). In the event of termination, you are no longer authorised
                                to access 7 Screening Room, but all restrictions imposed on you, licenses and waivers
                                granted by you and all Seven disclaimers and limitations of liability set out in these Terms
                                of Use (as amended) will survive.
                            </p>
                        </div>
                    </div>
                    <div>
                        <p className="copy-rights text-base font-normal text-gray-color">
                            Copyright @2023
                            <a href="#" className="text-red">Seven Network</a>, Inc
                            <a href="#" className="text-red"> | Privacy
                                policy</a>
                        </p>
                    </div>

                </div>
            </div>
            <div className=" backdrop-blur fixed inset-0 z-40"></div>

        </>
    )
}
