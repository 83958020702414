import React from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import Table from '../../common/Table';
import redcross from '../Header/images/red-cross-icon.svg'
import rightIcon from "../Header/images/right-icon.svg"

export default function AdminDashboard() {
    const Person = [
        {
            Catalogue: 'Priyanka',
            Status: 'Dolly',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'true'
        },
        {
            Catalogue: 'Parihar',
            Status: 'Dolly',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'true'

        },
        {
            Catalogue: 'shobha',
            Status: 'Dolly',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'true'

        },
        {
            Catalogue: 'karansing',
            Status: 'Dolly',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'false'

        },
        {
            Catalogue: 'fontu',
            Status: 'Transcoded',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'false'

        },
        {
            Catalogue: 'guddiya',
            Status: 'Transcoded',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'false'

        },
        {
            Catalogue: 'kirtika',
            Status: 'Transcoded',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'true'

        },
        {
            Catalogue: 'Akshay',
            Status: 'Transcoded',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'true'

        },
        {
            Catalogue: 'monu',
            Status: 'Transcoded',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'true'

        },
        {
            Catalogue: 'sonu',
            Status: 'Transcoded',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'false'

        },
        {
            Catalogue: 'pagal',
            Status: 'Transcoded',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'false'

        },
        {
            Catalogue: 'HBRI01-004"',
            Status: 'Transcoded',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'false'

        },
        {
            Catalogue: 'HBRI01-004"',
            Status: 'Transcoded',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'true'

        },
        {
            Catalogue: 'HBRI01-004"',
            Status: 'Transcoded',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'true'

        },
        {
            Catalogue: 'HBRI01-004"',
            Status: 'Transcoded',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'true'

        },
        {
            Catalogue: 'HBRI01-004"',
            Status: 'Transcoded',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'true'

        },
        {
            Catalogue: 'HBRI01-004"',
            Status: 'Transcoded',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'false'

        },
        {
            Catalogue: 'HBRI01-004"',
            Status: 'Transcoded',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'true'

        },
        {
            Catalogue: 'HBRI01-004"',
            Status: 'Transcoded',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'true'

        },
        {
            Catalogue: 'HBRI01-004"',
            Status: 'Dolly',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'true'

        },
        {
            Catalogue: 'HBRI01-004"',
            Status: 'Dolly',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'true'

        }, {
            Catalogue: 'HBRI01-004"',
            Status: 'Dolly',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'true'

        }, {
            Catalogue: 'HBRI01-004"',
            Status: 'Dolly',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'true'

        }, {
            Catalogue: 'HBRI01-004"',
            Status: 'Dolly',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'true'

        }, {
            Catalogue: 'HBRI01-004"',
            Status: 'Dolly',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'true'

        }, {
            Catalogue: 'HBRI01-004"',
            Status: 'Dolly',
            Associated: 'View Metadata',
            Submitted: '13 May 2022 10:22:12 AM',
            StatusIcon: 'true'

        },



    ];
    const columnHelper = createColumnHelper()
    const columns = [
        columnHelper.accessor('Catalogue', {
            cell: info => info.getValue(),
            header: () => <span className=''>Catalogue #</span>,
        }),
        columnHelper.accessor('Status', {
            id: 'Status',
            cell: (info) => <div className='flex space-x-3'>
                {(info?.cell?.row?.original?.StatusIcon === "true") ? <img src={rightIcon} alt='' /> : <img src={redcross} alt='' />}
                {/* {console.log(info?.cell?.row?.original?.StatusIcon === "true")} */}
                <span> {info.getValue()}</span>
            </div>
            ,
            header: () => <span className=''>Status</span>,

        }),
        columnHelper.accessor('Associated', {
            header: () => 'Associated Metadata',
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('Submitted', {
            header: () => <span>Submitted</span>,
        }),
    ]

    return (
        <div className="recent-videos-list lg:my-10 md:my-8 my-6 ">
            <div className="list-title my-6 flex justify-between items-center" >
                <h4 className="text-offwhite text-base sm:text-2xl lg:text-3xl">
                    Recent Transcoded Videos
                </h4>
                <a href="/" className="text-red-color whitespace-nowrap text-right sm:text-base text-sm underline-offset-2 underline hover:no-underline transition duration-300 ease-in-out">
                    View All History
                </a>
            </div>
            {/* <!-- recent-videos-listing-Table Start--> */}
            <div className="listing-table">
                <div className="overflow-x-auto shadow-md">
                    <Table value={Person} columns={columns} />

                </div>
            </div>
            {/* <!-- recent-videos-listing-Table Start--> */}
        </div>
    )
}
