import React from 'react'
import { RiLoader4Line } from "react-icons/ri";

export function FullScreenLoader() {
  return (
    <div className="backdrop-brightness-50 w-screen h-screen absolute inset-0 z-[99] flex">
      <i className="absolute inset-0 z-[99] flex justify-center items-center h-screen w-screen">
        <RiLoader4Line className="animate-spin" size={64} scale={10} color="white" />
      </i>
    </div>
  )
}